<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <article class="tile is-child box">
                    <h4 class="title">Статистика загрузок файлов</h4>

                    <nav class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <label class="label" style="margin-right: 3px">c</label>
                                <div class="control">
                                    <datepicker placeholder="C" :config="{ dateFormat: 'Y-m-d', maxDate: 'today' }"
                                                v-model="from"></datepicker>
                                </div>
                            </div>
                            <div class="level-item">
                                <label class="label" style="margin-right: 3px">по</label>
                                <div class="control">
                                    <datepicker placeholder="По" :config="{ dateFormat: 'Y-m-d', maxDate: 'today' }"
                                                v-model="until"></datepicker>
                                </div>
                            </div>

                            <div class="level-item">
                                <a class="button" :class="{ 'is-loading': loading }"
                                   @click.prevent="filter">Показать</a>
                            </div>
                        </div>
                    </nav>

                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <article class="tile is-child box">
                    <div class="notification is-primary" v-if="query.search && !haveItems()">
                        Ничего не найдено
                    </div>

                    <spinner v-show="loading"></spinner>

                    <table class="table is-striped" v-if="haveItems() && !loading">
                        <thead>
                        <tr>
                            <th>Название</th>
                            <th>Пользователь</th>
                            <th>PID</th>
                            <th>Описание</th>
                            <th>Имя файла</th>
                            <th>Путь</th>
                            <th>Дата</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(userfiles, index) in items">
                            <td width="12%">{{ userfiles.title }}</td>
                            <td width="12%">{{ userfiles.full_name }}</td>
                            <td width="12%">{{ userfiles.patient_id }}</td>
                            <td width="12%">{{ userfiles.description }}</td>
                            <td width="12%">{{ userfiles.filename }}</td>
                            <td width="12%">{{ userfiles.path }}</td>
                            <td width="12%">{{ userfiles.created_at }}</td>
                        </tr>
                        </tbody>
                    </table>

                    <pagination
                            @change="changePage"
                            :total-pages="pagination.total"
                            :limit="pagination.limit"
                            :page="pagination.current"
                    ></pagination>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import userFileHistory from '../../services/user-file-history'
import itemWithPararmListViewMixin from '../../mixins/itemWithParamListView'
import Datepicker from 'vue-bulma-datepicker'
import moment from 'moment'

export default {
  components: {
    Datepicker
  },
  data () {
    return {
      from: moment().subtract(1, 'd').format('YYYY-MM-DD'),
      until: moment().format('YYYY-MM-DD')
    }
  },
  mixins: [itemWithPararmListViewMixin(userFileHistory, 'user-file-history', {from: this.from, to: this.until, type: this.type})]
}
</script>
